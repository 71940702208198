import React from 'react'
import { ChainId, Currency, Token } from '@pancakeswap/sdk'
import { Text } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'

import { SUGGESTED_BASES } from '../../config/constants'
import QuestionHelper from '../QuestionHelper'
import { AutoRow } from '../Layout/Row'
import { CurrencyLogo } from '../Logo'

const Wrap = styled.div`
  display: grid;
  row-gap: 19px;
  border: 2px solid ${({ theme }) => theme.colors.metamaskBorder};
  border-radius: 16px;
  padding: 19px 22px 22px;
`

const CommonTokens = styled(AutoRow)`
  color: #989898;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
`

const BaseWrapper = styled.div<{ disable?: boolean }>`
  display: grid;
  grid-template-columns: 32px 1fr;
  column-gap: 12px;
  align-items: center;
  margin: 0;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ theme, disable }) => !disable && theme.colors.background};
  }

  background-color: ${({ theme, disable }) => disable && theme.colors.dropdown};
  opacity: ${({ disable }) => disable && '0.4'};
`

const TokensWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 8px;
  row-gap: 22px;
`

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
}: {
  chainId?: ChainId
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  const { t } = useTranslation()
  return (
    <Wrap>
      <CommonTokens>
        Common tokens
        <QuestionHelper text={t('These tokens are commonly paired with other tokens.')} ml="6px" width={16} />
      </CommonTokens>
      <TokensWrap>
        {/* <BaseWrapper
          onClick={() => {
            if (!selectedCurrency || !currencyEquals(selectedCurrency, ETHER)) {
              onSelect(ETHER)
            }
          }}
          disable={selectedCurrency === ETHER}
        >
          <CurrencyLogo size="32px" currency={ETHER} />
          <Text>BNB</Text>
        </BaseWrapper> */}
        {(chainId ? SUGGESTED_BASES[chainId] : [])?.map((token: Token) => {
          const selected = selectedCurrency instanceof Token && selectedCurrency.address === token.address
          return (
            <BaseWrapper onClick={() => !selected && onSelect(token)} disable={selected} key={token.address}>
              <CurrencyLogo size="32px" currency={token} />
              <Text>{token.symbol}</Text>
            </BaseWrapper>
          )
        })}
      </TokensWrap>
    </Wrap>
  )
}
