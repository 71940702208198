import React from 'react'
import styled from 'styled-components'
import { LogoFooterIcon } from 'uikit'
import { Social } from 'components/Social'

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: space-between;
  justify-items: center;
  background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' viewBox='0 0 1440 194' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M-172.497 84.9996C-1298 84.9996 -435.497 323.5 -252.997 493C-70.4976 662.499 835.504 455.5 1156 446.5C1476.5 437.5 1349.5 682.5 1407 583.499C1464.5 484.499 1531.5 387 1531.5 362C1531.5 337 1824 -104.757 1460.5 -5.00039C1097 94.7564 953 84.9996 -172.497 84.9996Z' fill='url(%23paint0_linear)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='1531.83' y1='-98.2322' x2='-496.354' y2='878.076' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2332CB54'/%3E%3Cstop offset='0.471875' stop-color='%2325BA46' stop-offset='1' /%3E%3C/linearGradient%3E%3CclipPath id='clip0'%3E%3Crect width='1440' height='194' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-size: 100% 100%;
  background-size: 100% 194px;
  padding: 114px 56px 45px;
  margin-top: 99px;
`

const Logo = styled.div`
  justify-self: start;
`

const Copy = styled.div`
  justify-self: end;
  color: white;
  font-weight: 500;
  font-size: 16px;
`

const Footer = () => {
  return (
    <Wrapper as="footer">
      <Logo>
        <LogoFooterIcon width="142" height="32" />
      </Logo>
      <Social isFooter />
      <Copy>© FROGGYDEX — {new Date().getFullYear()} COPYRIGHT</Copy>
    </Wrapper>
  )
}

export default Footer
