import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'FroggyDex',
  description:
    'The most popular AMM on BSC by user count! Earn CAKE through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by PancakeSwap), NFTs, and more, on a platform you can trust.',
  image: 'https://pancakeswap.finance/images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `${t('Home')} | FroggyDex`,
      }
    case '/swap':
      return {
        title: `${t('Exchange')} | FroggyDex`,
      }
    case '/add':
      return {
        title: `${t('Add Liquidity')} | FroggyDex`,
      }
    case '/remove':
      return {
        title: `${t('Remove Liquidity')} | FroggyDex`,
      }
    case '/liquidity':
      return {
        title: `${t('Liquidity')} | FroggyDex`,
      }
    case '/find':
      return {
        title: `${t('Import Pool')} | FroggyDex`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | FroggyDex`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | FroggyDex`,
      }
    case '/prediction/leaderboard':
      return {
        title: `${t('Leaderboard')} | FroggyDex`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | FroggyDex`,
      }
    case '/farms/history':
      return {
        title: `${t('Farms History')} | FroggyDex`,
      }
    case '/farms/auction':
      return {
        title: `${t('Farm Auctions')} | FroggyDex`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | FroggyDex`,
      }
    case '/pools/history':
      return {
        title: `Pools History | FroggyDex`,
      }
    case '/referral':
      return {
        title: `Referral Program | FroggyDex`,
      }
    case '/referral/farms':
      return {
        title: `Referral Farms | FroggyDex`,
      }
    case '/referral/launchpools':
      return {
        title: `Referral Launchpools | FroggyDex`,
      }
    case '/referral/referral-links':
      return {
        title: `Referral Links | FroggyDex`,
      }
    case '/referral/about':
      return {
        title: `About Referal | FroggyDex`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | FroggyDex`,
      }
    case '/collectibles':
      return {
        title: `${t('Collectibles')} | FroggyDex`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | FroggyDex`,
      }
    case '/profile':
      return {
        title: `${t('Your Profile')} | FroggyDex`,
      }
    case '/profile/tasks':
      return {
        title: `${t('Task Center')} | FroggyDex`,
      }
    case '/voting':
      return {
        title: `${t('Voting')} | FroggyDex`,
      }
    case '/voting/proposal':
      return {
        title: `${t('Proposals')} | FroggyDex`,
      }
    case '/voting/proposal/create':
      return {
        title: `${t('Make a Proposal')} | FroggyDex`,
      }
    case '/info':
      return {
        title: `${t('Overview')} | ${t('PancakeSwap Info & Analytics')}`,
        description: 'View statistics for Pancakeswap exchanges.',
      }
    case '/info/pools':
      return {
        title: `${t('Pools')} | ${t('PancakeSwap Info & Analytics')}`,
        description: 'View statistics for Pancakeswap exchanges.',
      }
    case '/info/tokens':
      return {
        title: `${t('Pools')} | ${t('PancakeSwap Info & Analytics')}`,
        description: 'View statistics for Pancakeswap exchanges.',
      }
    default:
      return null
  }
}
