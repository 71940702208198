import gsap from 'gsap'
import CustomEase from 'gsap/CustomEase'

const appearAnim = ({ id, items }) => {
  const props = { delay: 0.1, defaults: { ease: CustomEase.create('custom', 'M0,0 C0.25,0.1 0.25,1 1,1 ') } }
  const firstState = { scale: 0, opacity: 0, duration: 0.5 }

  switch (id) {
    case 1:
      gsap
        .timeline({ ...props })
        .from(items[2], { ...firstState }, 0)
        .from(items[1], { xPercent: -30, ease: 'elastic.out(1, 1)' }, 0.5)
        .from(items[1], { scale: 0.25, yPercent: 25, opacity: 0 }, 0.5)
      break

    case 2:
      gsap
        .timeline({ ...props })
        .from(items[4], { ...firstState }, 0)
        .from(items[1], { y: -13, opacity: 0 }, 0.5)
        .from(items[1], { x: 13, ease: 'elastic.out(1, 1)' }, 0.5)
        .from(items[2], { y: 18, opacity: 0 }, 0.5)
        .from(items[2], { x: 18, ease: 'elastic.out(1, 1)' }, 0.5)
        .from(items[3], { y: 36, opacity: 0 }, 0.5)
        .from(items[3], { x: -36, ease: 'elastic.out(1, 1)' }, 0.5)
      break

    case 3:
      gsap
        .timeline({ ...props })
        .from(items[3], { ...firstState }, 0)
        .from(items[1], { y: 50, opacity: 0, duration: 0.6 }, 0.5)
        .from(items[1], { x: 40, ease: 'elastic.out(1, 0.75)', duration: 0.6 }, 0.5)
        .from(items[2], { y: 33, opacity: 0, duration: 0.6 }, 0.5)
        .from(items[2], { x: -50, ease: 'elastic.out(1, 0.75)', duration: 0.6 }, 0.5)
      break

    default:
      break
  }
}

export default appearAnim
