import React from 'react'
import Svg from 'uikit/components/Svg/Svg'
import { SvgProps } from 'uikit/components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="133" height="114" viewBox="0 0 133 114" fill="none" {...props}>
      <g>
        <path d="M80.0164 17.2149L67.5005 9.81524L54.8692 2.61658C48.7475 -0.872196 41.2534 -0.872192 35.1318 2.61658L22.5005 9.81524L9.98461 17.2149C3.91888 20.8011 0.171836 27.3267 0.115902 34.4017L0.000488281 49L0.115902 63.5983C0.171836 70.6733 3.91888 77.1989 9.98461 80.7851L22.5005 88.1848L35.1318 95.3834C41.2534 98.8722 48.7475 98.8722 54.8692 95.3834L67.5005 88.1848L80.0164 80.7851C86.0821 77.1989 89.8291 70.6733 89.8851 63.5983L90.0005 49L89.8851 34.4017C89.8291 27.3267 86.0821 20.8011 80.0164 17.2149Z"
          fill="rgba(37, 186, 70, 0.5)" 
          style={{transform: "translate(22px, 4px)"}}/>

        <path
          d="M51 53C51 44.7157 57.7157 38 66 38C74.2843 38 81 44.7157 81 53C81 61.2843 74.2843 68 66 68C57.7157 68 51 61.2843 51 53Z"
          stroke="#25BA46"
          strokeWidth="2"
          fill="none"
        />
        <path d="M61 48L71 58M71 48L61 58" stroke="#25BA46" strokeWidth="2" strokeLinecap="round" />
      </g>
    </Svg>
  )
}

export default Icon
