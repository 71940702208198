import React from 'react'
import styled from 'styled-components'
import Button from './Button'

interface Props {
  onClick?: () => void
  width?: string
  height?: string
  background?: string
}

const Border = styled.div<{ width?: string; height?: string; }>`
  position: relative;
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || '60px'};
  border-radius: 16px;
  background: ${({theme})=>theme.colors.gradients.primaryGreen};
  padding: 2px;
  :hover {
    filter: drop-shadow(0px 0px 48px ${({theme}) => theme.colors.shadow});
  }
`

export const GradientOutlineButton: React.FC<Props> = ({ onClick, children, width, height, background }) => {
  return (
    <Border width={width} height={height}>
      <Button variant="outline" onClick={onClick} style={{ backgroundColor: background }}>
        {children}
      </Button>
    </Border>
  )
}
