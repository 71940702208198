import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { white } from 'style/colors'
import { Icon } from 'components/Icon'
import { LogoIcon, LogoLabel } from 'uikit'
import { SvgProps } from '../../../components/Svg'
import * as IconModule from '../icons'

import Accordion from './Accordion'
import { MenuEntry, LinkLabel } from './MenuEntry'
import MenuLink from './MenuLink'
import { PanelProps, PushedProps } from '../types'

const MenuButton = styled.div<{ isPushed: boolean }>`
  position: absolute;
  width: 28px;
  height: 28px;
  right: ${({ isPushed }) => `${isPushed ? '-22px' : '-34px'}`};
  transition: right 0.2s, background-color 200ms;
  top: 38px;
  background-color: ${({ theme }) => theme.colors.input};
  border: 1px solid ${white};
  display: grid;
  place-content: center;
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.primaryTint};
  }

  svg {
    transition: transform 200ms;
    transform: ${({ isPushed }) => (isPushed ? 'scaleX(-1)' : 'none')};
  }
`

interface Props extends PanelProps, PushedProps {
  isMobile: boolean
  isPushed: boolean
  togglePush: () => void
}

const Icons = IconModule as unknown as { [key: string]: React.FC<SvgProps> }

const Container = styled.div`
  display: grid;
  row-gap: 32px;
  position: relative;
`
const List = styled.div`
  display: grid;
  row-gap: 4px;
`
const LogoWrap = styled.div<{ isPushed: boolean }>`
  padding-left: ${({ isPushed }) => (isPushed ? '23px' : '5px')};
  display: grid;
  grid-template-columns: ${({ isPushed }) => (isPushed ? '45px 1fr' : ' 45px')};
  column-gap: 5px;
  align-items: center;
  color: #343434;
  transition: all 200ms;
`

const LabelWrap = styled.div<{ isPushed: boolean }>`
  display: ${({ isPushed }) => (isPushed ? 'block' : 'none')};
`

const PanelBody: React.FC<Props> = ({ isPushed, togglePush, pushNav, isMobile, links }) => {
  const location = useLocation()

  // Close the menu when a user clicks a link on mobile
  const handleClick = isMobile ? () => pushNav(false) : undefined

  return (
    <Container>
      <MenuButton aria-label="Toggle menu" onClick={togglePush} isPushed={isPushed}>
        <Icon name="arrow_back" size={16} />
      </MenuButton>
      <LogoWrap isPushed={isPushed}>
        <LogoIcon width="45px" height="40px" />
        <LabelWrap isPushed={isPushed}>
          <LogoLabel width="124px" height="32px" />
        </LabelWrap>
      </LogoWrap>

      <List>
        {links.map((entry: any) => {
          const LinkIcon = Icons[entry.icon]
          const { isDisabled } = entry
          const iconElement = <LinkIcon width="24px" mr="16px" />
          const calloutClass = entry.calloutClass ? entry.calloutClass : undefined

          if (entry.items) {
            const itemsMatchIndex = entry.items.findIndex((item) => item.href === location.pathname)
            const initialOpenState = entry.initialOpenState === true ? entry.initialOpenState : itemsMatchIndex >= 0

            return (
              <Accordion
                key={entry.label}
                isPushed={isPushed}
                pushNav={pushNav}
                icon={iconElement}
                label={entry.label}
                initialOpenState={initialOpenState}
                className={calloutClass}
                isActive={entry.items.some((item) => item.href === location.pathname)}
              >
                {isPushed &&
                  entry.items.map((item) => (
                    <MenuEntry
                      isPushed={isPushed}
                      isDisabled={isDisabled}
                      key={item.href}
                      secondary
                      isActive={item.href === location.pathname}
                      onClick={handleClick}
                    >
                      <MenuLink isDisabled={isDisabled} href={item.href}>
                        {item.label}
                      </MenuLink>
                    </MenuEntry>
                  ))}
              </Accordion>
            )
          }
          return (
            <MenuEntry
              isPushed={isPushed}
              isDisabled={isDisabled}
              isActive={entry.href === location.pathname}
              className={calloutClass}
              key={entry.label}
            >
              <MenuLink isDisabled={isDisabled} key={entry.label} href={entry.href} onClick={handleClick}>
                {iconElement}
                <LinkLabel isPushed={isPushed}>{entry.label}</LinkLabel>
              </MenuLink>
            </MenuEntry>
          )
        })}
      </List>
    </Container>
  )
}

export default PanelBody
