import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Label: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 121 31" {...props}>
      <path
        fill="#25BA46"
        d="M2.02 9.16h9.84v2.26H4.6v3.53h6.55v2.25H4.61v5.1H2.02V9.17Zm11.33 0h6.99c1.14 0 2.05.31 2.74.92.7.62 1.05 1.42 1.05 2.43v1.31c0 .65-.19 1.22-.56 1.7-.37.48-.9.85-1.6 1.1v.03c.4.27.7.57.9.9.21.34.42.81.62 1.41l1.05 3.35h-2.68l-1-3.12a2.62 2.62 0 0 0-.67-1.2c-.3-.27-.65-.4-1.07-.4h-3.18v4.72h-2.59V9.16Zm6.5 6.2c.52 0 .94-.14 1.24-.41.3-.3.45-.66.45-1.11v-1.22c0-.36-.11-.65-.34-.87a1.22 1.22 0 0 0-.9-.33h-4.36v3.94h3.9Zm10.8 7.1c-1 0-1.87-.21-2.64-.62a4.6 4.6 0 0 1-1.76-1.77 5.48 5.48 0 0 1-.62-2.65v-3.38c0-1 .2-1.87.62-2.62A4.43 4.43 0 0 1 28 9.65a5.3 5.3 0 0 1 2.63-.64h2c.98 0 1.85.22 2.6.64.76.41 1.35 1 1.77 1.77.42.75.63 1.62.63 2.62v3.38c0 1-.2 1.89-.63 2.65a4.5 4.5 0 0 1-1.77 1.77c-.75.41-1.62.62-2.6.62h-2Zm1.98-2.3c.73 0 1.31-.26 1.75-.78.45-.53.67-1.22.67-2.07v-3.15c0-.85-.22-1.54-.67-2.07a2.16 2.16 0 0 0-1.75-.79h-1.99a2.2 2.2 0 0 0-1.76.8 3.12 3.12 0 0 0-.66 2.06v3.15c0 .85.22 1.54.66 2.07.45.52 1.04.79 1.76.79h2Zm11.47 2.3c-.97 0-1.82-.21-2.56-.62-.74-.42-1.31-1-1.72-1.75-.4-.76-.6-1.64-.6-2.63v-3.45c0-1 .2-1.86.6-2.61.4-.77.98-1.35 1.72-1.77a5.29 5.29 0 0 1 2.6-.62h2.1c.94 0 1.76.2 2.48.57.7.37 1.26.9 1.65 1.6.4.68.6 1.48.6 2.38H48.4c0-.68-.2-1.22-.6-1.64a2.1 2.1 0 0 0-1.57-.62h-2.1c-.7 0-1.27.27-1.7.8a3.07 3.07 0 0 0-.63 2.02v3.23c0 .84.2 1.52.62 2.05.42.5.98.77 1.67.77h2.21c.64 0 1.17-.24 1.58-.7.42-.46.64-1.04.64-1.73v-.4h-3.64v-2.26h6.2v2.66c0 .92-.2 1.73-.61 2.44-.4.72-.96 1.27-1.69 1.68-.73.4-1.55.6-2.48.6h-2.2Z"
      />
      <path
        fill="#1A1D27"
        d="M57.46 22.46c-.96 0-1.81-.21-2.55-.62-.74-.42-1.31-1-1.73-1.75-.4-.76-.6-1.64-.6-2.63v-3.45c0-1 .2-1.86.6-2.61.42-.77 1-1.35 1.73-1.77a5.29 5.29 0 0 1 2.6-.62h2.1c.93 0 1.76.2 2.47.57.72.37 1.27.9 1.65 1.6.4.68.6 1.48.6 2.38h-2.55c0-.68-.2-1.22-.6-1.64a2.1 2.1 0 0 0-1.58-.62h-2.1c-.7 0-1.26.27-1.69.8a3.07 3.07 0 0 0-.64 2.02v3.23c0 .84.2 1.52.62 2.05.43.5.98.77 1.67.77h2.22c.64 0 1.16-.24 1.58-.7.42-.46.63-1.04.63-1.73v-.4h-3.64v-2.26h6.2v2.66c0 .92-.2 1.73-.6 2.44a4.3 4.3 0 0 1-1.7 1.68c-.72.4-1.55.6-2.47.6h-2.22Zm11.96-5.26-4.87-8.04h2.82l3.34 5.65 3.34-5.65h2.82l-4.86 8.04v5.1h-2.6v-5.1Zm15.65 5.1c-.54 0-.98-.16-1.32-.48a1.7 1.7 0 0 1-.5-1.28V9.16h6.27c1 0 1.87.2 2.63.57a4.37 4.37 0 0 1 2.4 3.98v3.68c0 1.61-.42 2.84-1.26 3.68-.84.82-2.07 1.24-3.7 1.24h-4.52Zm4.45-2.28c.8 0 1.4-.24 1.82-.7.41-.46.62-1.14.62-2.05v-3.45a2.2 2.2 0 0 0-.7-1.7 2.54 2.54 0 0 0-1.82-.67h-3.6v8.57h3.68Zm8.73 2.29c-.54 0-.98-.17-1.31-.5a1.7 1.7 0 0 1-.51-1.27V9.16h10.02v2.26h-7.43v3.04h6.87v2.25h-6.87v3.34h7.44v2.26h-8.21Zm13.35-6.7-4.4-6.45h2.95l2.93 4.32 2.93-4.32h2.93l-4.4 6.44 4.59 6.7h-2.95l-3.1-4.58-3.11 4.59h-2.95l4.58-6.7Z"
      />
    </Svg>
  )
}

export default Label
