import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Text, Button } from 'uikit'
import { appearAnim, killAnim } from './anim'

import image from './img/image.svg'
import shadow from './img/shadow.svg'

const Wrapper = styled.div`
  display: flex;
  margin-top: 32px;
`

const TextWrap = styled.div`
  padding-top: 80px;
  padding-bottom: 64px;
  max-width: 544px;
`

const ImgWrap = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  position: relative;
`

const Img = styled.img`
  width: 100%;
  max-width: 544px;
`

const Shadow = styled.img`
  bottom: -35px;
  right: -20px;
  z-index: -1;
  position: absolute;
`

const ColoredText = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`

const TopBanner: React.FC = () => {
  const iconRef = useRef(null)
  const shadowRef = useRef(null)

  useEffect(() => {
    appearAnim({ icon: iconRef.current, shadow: shadowRef.current })
    return () => killAnim()
  }, [])
  return (
    <Wrapper>
      <TextWrap>
        <Text mb="24px" fontSize="32px" color="contrast" bold maxWidth="314px">
          Launch Your Project on <ColoredText>FroggyDex</ColoredText> Now!
        </Text>
        <Text fontSize="16px" lineHeight="24px" color="textDisabled" mb="16px">
          Farms stimulate users to provide liquidity for your trading pair by distributing FROGGY tokens to your pair’s
          LP token holders. Launchpool is a platform where a project owner can distribute tokens to BSC users who stake
          FROGGY tokens in the pool. When a project applies for FroggyDex Launchpool we can also create a new farming
          pair (subject to discussion).
        </Text>
        <Text fontSize="16px" lineHeight="24px" color="textDisabled" mb="24px">
          FroggyDex Launchpool and Farms are platforms that help project teams promote their token and get exposure to
          thousands of active FroggyDex users across the globe. We look for strong teams with clear and innovative
          vision in the crypto space. If you think you are one of the projects, do not wait any longer and apply below.{' '}
        </Text>
        <Button variant="primary" width="412px">
          Apply to Launch
        </Button>
      </TextWrap>
      <ImgWrap>
        <Shadow src={shadow} ref={shadowRef} />
        <Img src={image} ref={iconRef} />
      </ImgWrap>
    </Wrapper>
  )
}

export default TopBanner
