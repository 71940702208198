import { Colors } from './types'

export const baseColors = {
  failure: '#cc3300',
  primary: '#25BA46',
  primaryTint: '#e4f4ec',
  primaryBright: '#32CB54',
  primaryDark: '#0098A1',
  secondary: '#25BA46',
  success: '#25BA46',
  warning: '#FF5733',
  transparent: "transparent",
}

export const additionalColors = {
  binance: '#F0B90B',
  overlay: '#343434',
  gold: '#FFC700',
  silver: '#B2B2B2',
  bronze: '#E7974D',
  metamaskBorder: 'rgba(219, 219, 219, 0.4)',
  grayBorder: '#f1f1f1',
  bgWithOpacity: 'rgba(216, 231, 219, 0.8);',
  gray: '#989898',
}

export const lightColors: Colors = {
  ...baseColors,
  ...additionalColors,
  background: '#F7FFF8',
  backgroundDisabled: '#D8E7DB',
  backgroundAlt: '#FFFFFF',
  backgroundAlt2: 'rgba(255, 255, 255, 0.7)',
  cardBorder: '#CDCDCD',
  contrast: '#191326',
  dropdown: '#F6F6F6',
  dropdownDeep: '#EEEEEE',
  invertedContrast: '#FFFFFF',
  shadow: 'rgba(37, 186, 70, 0.4)',
  greenTint10: 'rgba(50, 203, 84, 0.1)',
  greenTint50: 'rgba(50, 203, 84, 0.5)',
  input: 'rgb(227, 248, 231)',
  inputSecondary: '#25BA461A',
  tertiary: 'rgba(236, 236, 236, 0.4)',
  lightGreenTertiary: "rgba(37, 186, 70, 0.1)",
  text: '#343434',
  textDisabled: '##BDBDBD',
  textSubtle: '#343434',
  disabled: '#E9EAEB',
  gradients: {
    bubblegum: '#ffffff',
    inverseBubblegum: 'linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)',
    cardHeader: 'linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)',
    blue: 'linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)',
    violet: 'linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)',
    violetAlt: 'linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)',
    gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
    primaryGreen: 'linear-gradient(262.79deg, #32CB54 2.72%, #25BA46 95.76%);',
    poolGreen: 'linear-gradient(138.44deg, #25BA46 -5.34%, rgba(37, 186, 70, 0.1) 133.63%)',
  },
}

export const darkColors: Colors = {
  ...baseColors,
  ...additionalColors,
  secondary: '#9A6AFF',
  background: '#F7FFF8',
  backgroundDisabled: '#3c3742',
  backgroundAlt: '#27262c',
  backgroundAlt2: 'rgba(39, 38, 44, 0.7)',
  cardBorder: '#383241',
  contrast: '#FFFFFF',
  dropdown: '#1E1D20',
  greenTint10: 'rgba(50, 203, 84, 0.1)',
  greenTint50: 'rgba(50, 203, 84, 0.5)',
  dropdownDeep: '#100C18',
  invertedContrast: '#191326',
  input: '#372F47',
  inputSecondary: '#262130',
  shadow: 'rgba(37, 186, 70, 0.4)',
  primaryDark: '#0098A1',
  tertiary: '#353547',
  lightGreenTertiary: "rgba(37, 186, 70, 0.1)",
  text: '#1C212B',
  textDisabled: '#666171',
  textSubtle: '#B8ADD2',
  disabled: '#524B63',
  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)',
    inverseBubblegum: 'linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)',
    cardHeader: 'linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)',
    blue: 'linear-gradient(180deg, #00707F 0%, #19778C 100%)',
    violet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
    violetAlt: 'linear-gradient(180deg, #434575 0%, #66578D 100%)',
    gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
    primaryGreen: 'linear-gradient(262.79deg, #32CB54 2.72%, #25BA46 95.76%);',
    poolGreen: 'linear-gradient(138.44deg, #25BA46 -5.34%, rgba(37, 186, 70, 0.1) 133.63%)',
  },
}
