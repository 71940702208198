import gsap from 'gsap'
import CustomEase from 'gsap/CustomEase'

const appearAnim = ({ icon }) => {
  gsap
    .timeline({ delay: 0.1 })
    .from(
      icon,
      {
        opacity: 0,
        scale: 0,
        duration: 0.6,
        yPercent: 50,
        ease: CustomEase.create('custom', 'M0,0 C0.25,0.1 0.25,1 1,1 '),
      },
      0.5,
    )
    .from(
      icon,
      {
        xPercent: -200,
        ease: 'elastic.out(1.5, 0.4)',
        duration: 1.1,
      },
      0,
    )
}

export default appearAnim
