import styled from 'styled-components'
import { InputProps, scales } from './types'

/**
 * Priority: Warning --> Success
 */

const Input = styled.input<InputProps>`
  border-radius: 16px;
  border: 2px solid ${({theme}) => theme.colors.metamaskBorder};
  color: ${({ theme }) => theme.colors.text};
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  outline: 0;
  padding: 15px 16px;
  width: 100%;

  &::placeholder {
    color: rgba(${({theme})=>theme.colors.overlay}, 0.6);
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.backgroundDisabled};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.textDisabled};
    cursor: not-allowed;
  }

  &:focus:not(:disabled) {
    outline: 0;
    border: 2px solid ${({theme})=>theme.colors.shadow};
  }
`

Input.defaultProps = {
  scale: scales.MD,
  isSuccess: false,
  isWarning: false,
}

export default Input
