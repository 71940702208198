/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Text } from 'uikit'
import styled from 'styled-components'

import ImagesWrap from '../ImagesWrap'
import stepOne1 from './img/step-one-1.svg'
import stepOne2 from './img/step-one-2.svg'
import stepOne3 from './img/step-one-3.svg'
import stepTwo1 from './img/step-two-1.svg'
import stepTwo2 from './img/step-two-2.svg'
import stepTwo3 from './img/step-two-3.svg'
import stepTwo4 from './img/step-two-4.svg'
import stepTwo5 from './img/step-two-5.svg'
import stepThree1 from './img/step-three-1.svg'
import stepThree2 from './img/step-three-2.svg'
import stepThree3 from './img/step-three-3.svg'
import stepThree4 from './img/step-three-4.svg'

const Wrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 64px;
  column-gap: 20px;
`

const Item = styled.div`
  background: #ffffff;
  border-radius: 16px;
  padding: 0 0 28px;
`

const TextWrap = styled.div`
  padding: 0 28px;
  display: grid;
  row-gap: 16px;
`

const InviteInstruction = () => {
  const data = [
    {
      id: 1,
      title: 'Get a referral link',
      images: [stepOne3, stepOne2, stepOne1],
      text: 'Connect a wallet and generate your referral link in the Referral section.',
    },
    {
      id: 2,
      title: 'Invite friends',
      images: [stepTwo5, stepTwo4, stepTwo3, stepTwo2, stepTwo1],
      text: 'Invite your friends to register via your referral link ',
    },
    {
      id: 3,
      title: 'Earn crypto',
      images: [stepThree4, stepThree3, stepThree2, stepThree1],
      text: 'Receive referral rewards in FROGGY tokens from your friends’ earnings & swaps',
    },
  ]
  return (
    <Wrap>
      {data.map((item, i) => (
        <Item key={item.id}>
          <ImagesWrap {...item} />
          <TextWrap>
            <Text fontSize="16px" color="#343434" fontWeight="600">
              {item.title}
            </Text>
            <Text fontSize="16px" color="#7E7E7E">
              {item.text}
            </Text>
          </TextWrap>
        </Item>
      ))}
    </Wrap>
  )
}

export default InviteInstruction
