import React from 'react'
import Svg from '../Svg'

const Icon: React.FC<any> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5ZM9.375 8.75C9.375 8.40482 9.65482 8.125 10 8.125C10.3452 8.125 10.625 8.40482 10.625 8.75V13.75C10.625 14.0952 10.3452 14.375 10 14.375C9.65482 14.375 9.375 14.0952 9.375 13.75V8.75ZM10 6.25C9.65482 6.25 9.375 6.52982 9.375 6.875C9.375 7.22018 9.65482 7.5 10 7.5C10.3452 7.5 10.625 7.22018 10.625 6.875C10.625 6.52982 10.3452 6.25 10 6.25Z"
        fill="#989898"
      />
    </Svg>
  )
}

export default Icon
