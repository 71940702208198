/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef } from 'react'
import { Text } from 'uikit'
import styled from 'styled-components'
import appearAnim from './anim'

const Wrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 255px;
`

const Image = styled.img`
  position: absolute;
  width: 100%;
`

const ImagesWrap = ({ images, title, id }) => {
  const itemsRef = useRef([])
  useEffect(() => {
    appearAnim({ id, items: itemsRef.current })
  }, [id])

  return (
    <Wrap>
      {images.map((src, i) => (
        <Image
          ref={(el) => {
            itemsRef.current[i] = el
          }}
          src={src}
          alt={`${title}-${i}`}
          key={`${title}-${i}`}
        />
      ))}
    </Wrap>
  )
}

export default ImagesWrap
