export enum WrapType {
  NOT_APPLICABLE,
  WRAP,
  UNWRAP,
}

const NOT_APPLICABLE = { wrapType: WrapType.NOT_APPLICABLE }
/**
 * Given the selected input and output currency, return a wrap callback
 * @param inputCurrency the selected input currency
 * @param outputCurrency the selected output currency
 * @param typedValue the user input value
 */
export default function useWrapCallback(): { wrapType: WrapType; execute?: undefined | (() => Promise<void>); inputError?: string } {
  // return useMemo(() => {
  //   if (!wethContract || !chainId || !inputCurrency || !outputCurrency) return NOT_APPLICABLE

  //   const sufficientBalance = inputAmount && balance && !balance.lessThan(inputAmount)

  //   if (inputCurrency === ETHER && currencyEquals(WETH[chainId], outputCurrency)) {
  //     return {
  //       wrapType: WrapType.WRAP,
  //       execute:
  //         sufficientBalance && inputAmount
  //           ? async () => {
  //               try {
  //                 const txReceipt = await callWithGasPrice(wethContract, 'deposit', undefined, {
  //                   value: `0x${inputAmount.raw.toString(16)}`,
  //                 })
  //                 addTransaction(txReceipt, { summary: `Wrap ${inputAmount.toSignificant(6)} BNB to WBNB` })
  //               } catch (error) {
  //                 console.error('Could not deposit', error)
  //               }
  //             }
  //           : undefined,
  //       inputError: sufficientBalance ? undefined : 'Insufficient BNB balance',
  //     }
  //   }
  //   if (currencyEquals(WETH[chainId], inputCurrency) && outputCurrency === ETHER) {
  //     return {
  //       wrapType: WrapType.UNWRAP,
  //       execute:
  //         sufficientBalance && inputAmount
  //           ? async () => {
  //               try {
  //                 const txReceipt = await callWithGasPrice(wethContract, 'withdraw', [
  //                   `0x${inputAmount.raw.toString(16)}`,
  //                 ])
  //                 addTransaction(txReceipt, { summary: `Unwrap ${inputAmount.toSignificant(6)} WBNB to BNB` })
  //               } catch (error) {
  //                 console.error('Could not withdraw', error)
  //               }
  //             }
  //           : undefined,
  //       inputError: sufficientBalance ? undefined : 'Insufficient WBNB balance',
  //     }
  //   }
  //   return NOT_APPLICABLE
  // }, [wethContract, chainId, inputCurrency, outputCurrency, inputAmount, balance, addTransaction, callWithGasPrice])
  return NOT_APPLICABLE
}
