import gsap from 'gsap'
import CustomEase from 'gsap/CustomEase'
import ScrollTrigger from 'gsap/ScrollTrigger'

const appearAnim = ({ icon, shadow }) => {
  gsap
    .timeline({
      defaults: { ease: CustomEase.create('custom', 'M0,0 C0.25,0.1 0.25,1 1,1 '), opacity: 0 },
      scrollTrigger: {
        trigger: icon,
        start: 'top 95%',
        id: 'coinSt',
      },
    })
    .from(icon, { scale: 0.1, transformOrigin: '100% 100%', duration: 1 }, 0)
    .from(shadow, {}, 1)
}

const killAnim = () => {
  ScrollTrigger.getById('coinSt')?.kill()
}

export { appearAnim, killAnim }
