import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 37 40" {...props}>
      <path
        fill="#25BA46"
        d="M32.66 7.03 27.56 4 22.4 1.07a8.13 8.13 0 0 0-8.06 0L9.18 4l-5.1 3.02a8.22 8.22 0 0 0-4.03 7.01L0 20l.05 5.96a8.22 8.22 0 0 0 4.03 7.01L9.18 36l5.16 2.94a8.13 8.13 0 0 0 8.06 0L27.55 36l5.11-3.02a8.22 8.22 0 0 0 4.03-7.01l.04-5.96-.04-5.96a8.22 8.22 0 0 0-4.03-7.01Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M23.8 10.8c.5.77.37 1.6-.8 5.52-.49 1.6-.93 3.64-1 4.52-.15 2.07.3 2.62 2.14 2.62 1.38 0 2.26-.37 3.15-1.33 1.77-1.88 4.14-1.81 4.14.13 0 1.2-.69 1.57-5.33 2.9-1.62.46-1.96.67-3.38 2.09-.87.86-1.96 1.72-2.42 1.91-1 .42-2.6.24-3.67-.41-.7-.42-1.74-2-1.98-2.62a6.72 6.72 0 0 0-2.17-2.18c-.57-.29-1.7-.5-3.19-.6-2.86-.2-3.53-.45-3.87-1.4-.25-.72-.1-1.18.6-1.68.59-.43 2.26-.15 4 .67 2.06.98 4.38 1.8 5.1 1.8.42 0 .5-.16.5-.92 0-1.33-.95-3.03-3.13-5.56a17.76 17.76 0 0 1-2.05-2.68c-.16-.63.33-1.5 1.1-1.92 1.25-.7 2.32.51 3.72 4.2.94 2.46 1.72 3.86 2.56 4.56.93.77 1.22.78 1.9.07.82-.87 1.04-1.91 1.24-5.77.15-2.93.25-3.64.62-4.04a1.4 1.4 0 0 1 2.21.13Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default Icon
