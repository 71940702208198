import React from 'react'
import styled from 'styled-components'
import { Text, Heading, ArrowBackIcon, NotificationDot, Flex, IconButton } from 'uikit'
import { Link } from 'react-router-dom'
import { useExpertModeManager } from 'state/user/hooks'
import GlobalSettings from 'components/Menu/GlobalSettings'
import SubNav from 'components/Menu/SubNav'
import QuestionHelper from '../QuestionHelper'

interface Props {
  title: string
  subtitle?: string
  helper?: string
  backTo?: string
  hasSettings?: boolean
}

const AppHeaderContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 53px;
  padding: 0 32px 0 22px;
`

const AppHeader: React.FC<Props> = ({ title, subtitle, helper, backTo, hasSettings = false }) => {
  const [expertMode] = useExpertModeManager()

  return (
    <>
      <SubNav />
      <AppHeaderContainer>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          {backTo && (
            <IconButton as={Link} to={backTo}>
              <ArrowBackIcon width="24px" />
            </IconButton>
          )}

          <Flex flexDirection="column">
            <Heading as="h2" mb="7px">
              {title}
            </Heading>
            <Flex alignItems="center">
              <Text color="textSubtle" fontSize="16px">
                {subtitle}
              </Text>
            </Flex>
          </Flex>
          {helper && <QuestionHelper text={helper} />}
        </Flex>
        {hasSettings && (
          <Flex alignItems="center">
            <NotificationDot show={expertMode}>
              <GlobalSettings />
            </NotificationDot>
            {/* // <Transactions /> */}
          </Flex>
        )}
      </AppHeaderContainer>
    </>
  )
}

export default AppHeader
