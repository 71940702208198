import React, { useState } from 'react'
import styled from 'styled-components'
import {
  InjectedModalProps,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  Heading,
  ModalBody,
  ModalCloseButton,
} from 'uikit'
import TransactionSettings from './TransactionSettings'
import ExpertModal from './ExpertModal'

const StyledModalBody = styled(ModalBody)`
  padding: 9px 22px 22px;
`

const SettingsModal: React.FC<InjectedModalProps> = ({ onDismiss }) => {
  const [showConfirmExpertModal, setShowConfirmExpertModal] = useState(false)

  if (showConfirmExpertModal) {
    return (
      <ExpertModal
        setShowConfirmExpertModal={setShowConfirmExpertModal}
        onDismiss={onDismiss}
      />
    )
  }

  return (
    <ModalContainer minWidth="520px">
      <ModalHeader>
        <ModalTitle>
          <Heading>Settings</Heading>
        </ModalTitle>
        <ModalCloseButton onDismiss={onDismiss} />
      </ModalHeader>
      <StyledModalBody>
        <TransactionSettings />
      </StyledModalBody>
    </ModalContainer>
  )
}

export default SettingsModal
