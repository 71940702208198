import React from 'react'
import styled, { keyframes, DefaultTheme } from 'styled-components'
import { neutral400, white } from 'style/colors'

import { MENU_ENTRY_HEIGHT } from '../config'

export interface Props {
  isPushed: boolean
  isDisabled?: boolean
  secondary?: boolean
  isActive?: boolean
  theme: DefaultTheme
}

const rainbowAnimation = keyframes`
  0%,
  100% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
`

const LinkLabel = styled.div<{ isPushed: boolean }>`
  color: ${({ isPushed }) => (isPushed ? 'currentColor' : 'transparent')};
  flex-grow: 1;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.38;
  display: ${({ isPushed }) => (isPushed ? 'block' : 'none')};
`

const MenuEntry = styled.div<Props>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};

  height: ${MENU_ENTRY_HEIGHT}px;
  padding: ${({ isPushed }) => (isPushed ? '16px 28px' : '16px')};
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.primary : 'transparent')};
  color: ${({ isActive, isDisabled, theme }) => (isDisabled ? neutral400 : isActive ? white : theme.colors.text)};

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  border-radius: 16px;
  transition: padding 200ms;
  white-space: nowrap;
  a,
  span {
    display: grid;
    grid-template-columns: ${({ isPushed }) => (isPushed ? '24px 1fr' : '24px')};
    align-items: center;
    column-gap: 16px;
    width: 100%;
    height: 100%;
  }

  svg {
    fill: ${({ isActive, isDisabled, theme }) => (isActive && !isDisabled ? white : theme.colors.text)};
  }
  &:hover {
    background-color: ${({ isActive, isDisabled, theme }) => (isDisabled ? 'transparent' : isActive ? theme.colors.primary : theme.colors.primaryTint)};
  }

  // Safari fix
  flex-shrink: 0;

  &.rainbow {
    background-clip: text;
    animation: ${rainbowAnimation} 3s ease-in-out infinite;
    background: ${({ theme }) => theme.colors.gradients.bubblegum};
    background-size: 400% 100%;
  }
`
MenuEntry.defaultProps = {
  secondary: false,
  isActive: false,
  role: 'button',
}

const LinkLabelMemo = React.memo(LinkLabel, (prev, next) => prev.isPushed === next.isPushed)

export { MenuEntry, LinkLabelMemo as LinkLabel }
