import { scales, variants } from './types'
import {lightColors} from '../../theme/colors'

export const scaleVariants = {
  [scales.LG]: {
    height: '60px',
    padding: '0 24px',
  },
  [scales.MD]: {
    height: '48px',
    padding: '0 24px',
  },
  [scales.SM]: {
    height: '32px',
    padding: '0 16px',
  },
  [scales.XS]: {
    height: '20px',
    fontSize: '12px',
    padding: '0 8px',
  },
}

export const styleVariants = {
  [variants.PRIMARY]: {
    background: lightColors.gradients.primaryGreen,
    boxShadow: 'none',
    minWidth: '220px',
    height: '60px',
    color: lightColors.backgroundAlt,
    '&:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active)': {
      opacity: 1,
      boxShadow: `0px 8px 48px ${lightColors.shadow}` ,
    },
    ':disabled': {
      background: lightColors.disabled,
    },
  },
  [variants.SECONDARY]: {
    backgroundColor: lightColors.transparent,
    border: '2px solid',
    borderColor: lightColors.primary,
    boxShadow: 'none',
    color: lightColors.primary,
    ':disabled': {
      backgroundColor: lightColors.transparent,
    },
  },
  [variants.TERTIARY]: {
    backgroundColor: lightColors.tertiary,
    boxShadow: 'none',
    color: lightColors.text,
    fontWeight: 500,
    fontSize: '18px',
    height: '46px',
    width: '64px',
    borderRadius: '16px',
    '&:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active)': {
      opacity: 1,
      backgroundColor: lightColors.lightGreenTertiary,
      color: lightColors.text,
    },
  },
  [variants.TERTIARY_ACTIVE]: {
    backgroundColor: lightColors.primaryBright,
    boxShadow: 'none',
    fontWeight: 500,
    fontSize: '18px',
    height: '46px',
    width: '64px',
    borderRadius: '16px',
    color: lightColors.backgroundAlt,
    '&:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active)': {
      opacity: 1,
      backgroundColor: lightColors.primaryBright,
      color: lightColors.backgroundAlt,
    },
  },
  [variants.SUBTLE]: {
    backgroundColor: lightColors.backgroundAlt,
    color: lightColors.primary,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    borderRadius: '10px',
    height: '48px',
    '&:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active)': {
      opacity: 1,
    },
  },
  [variants.DANGER]: {
    backgroundColor: lightColors.failure,
    color: lightColors.backgroundAlt,
  },
  [variants.SUCCESS]: {
    backgroundColor: lightColors.success,
    color: lightColors.backgroundAlt,
  },
  [variants.TEXT]: {
    backgroundColor: lightColors.transparent,
    color: lightColors.primary,
    boxShadow: 'none',
    '&:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active)': {
      opacity: 1,
      color: lightColors.primary,
    },
    '&:active:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled)': {
      opacity: 1,
      color: lightColors.primary,
    },
  },
  [variants.LIGHT]: {
    backgroundColor: 'input',
    color: lightColors.textSubtle,
    boxShadow: 'none',
  },
  [variants.OUTLINE]: {
    backgroundColor: lightColors.backgroundAlt,
    width: '100%',
    height: '100%',
    color: lightColors.secondary,
    boxShadow: 'none',
    borderRadius: '14px',
    '&:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active)': {
      opacity: 1,
      borderColor: lightColors.transparent,
    },
  },
  [variants.METAMASK]: {
    backgroundColor: lightColors.transparent,
    border: `2px solid ${lightColors.metamaskBorder}` ,
    borderHeight: '60px',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '22px',
    color: lightColors.text,
    boxShadow: 'none',
    justifyContent: 'left',
    padding: '14px 16px',
    height: '60px',
    transition: 'all 200ms',
    '&:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active)': {
      opacity: 1,
      borderColor: lightColors.primary,
    },
  },
  [variants.LINK]: {
    backgroundColor: lightColors.transparent,
    color: lightColors.primary,
    boxShadow: 'none',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    height: '24px',
    padding: 0,
  },
  [variants.TAB]: {
    backgroundColor: lightColors.backgroundAlt,
    borderRadius: '10px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: lightColors.secondary,
    boxShadow: 'none',
    height: '48px',
    padding: '13px',
    minWidth: '116px',
  },
}
